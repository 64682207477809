import React from "react";
import { Link } from "gatsby";
import Helmet from "react-helmet";
import { graphql } from 'gatsby'

// import '../css/index.css'; // add some style if you want!

export default function Blog({ data }) {
  const { edges: posts } = data.allMarkdownRemark;
  return (
    <div className="blog-posts transparentContainer">
      {posts
        .filter(post => post.node.frontmatter.title.length > 0)
        .map(({ node: post }) => {
          return (
            <div className="blog-post-preview whiteCard bottomSpace" key={post.id}>
              <h1>
                <Link to={post.frontmatter.path}>{post.frontmatter.title}</Link>
              </h1>
              {/*<h2>{post.frontmatter.date}</h2>*/}  
              <Link to={post.frontmatter.path}><p>{post.excerpt}</p></Link>
              <img src={post.frontmatter.image} />
            </div>
          );
        })}
    </div>
  );
}

export const pageQuery = graphql`
  query IndexQuery {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          excerpt(pruneLength: 250)
          id
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            path
            description
          }
        }
      }
    }
  }
`;